import { Api } from "@/apis";
export const allProps = {
  isShowBtn: {
    addBtn: false,
    dialogFormBtn: true,
    searchBtn: true,
    excelBtn: true,
    jsonFields: {
      shopName: "门店名",
      s_id: "设备号",
      gameName: "游戏名",
      DD: "订单id",
      money: "订单价格",
      moneyz: "打折后价格",
      fee_hardware: "设备分成金额",
      fee_field: "场地分成金额",
      fee_content: "内容分成金额",
      created_at: "开始时间",
      updated_at: "完成时间",
      state: "订单状态",
      channel: "收款渠道",
    },
  },
  dialog: {
    isShow: false,
    title: "编辑",
  },
  getMethods: {
    method: "get",
    initTable: "false",
  },
  data: {},
  items: [
    {
      label: "选择门店",
      prop: "selectShop",
      type: ["search"],
      Ftype: "cascader",
      options: [],
    },
    {
      label: "选择时间",
      prop: "time",
      type: ["search"],
      Ftype: "datePicker",
      dateProps: [],
      dateProps: ["startAt", "endAt", "YYYY-MM-DD"],
    },
    {
      label: "门店名",
      prop: "shopName",
      type: ["table"],
    },
    {
      label: "设备号",
      prop: "s_id",
      type: ["table"],
    },
    {
      label: "游戏名",
      prop: "gameName",
      type: ["table"],
    },
    {
      label: "订单id",
      prop: "DD",
      type: ["table"],
    },
    {
      label: "订单价格",
      prop: "money",
      type: ["table"],
    },
    {
      label: "打折后价格",
      prop: "moneyz",
      type: ["table"],
    },
    {
      label: "设备分成金额",
      prop: "fee_hardware",
      type: ["table"],
    },
    {
      label: "场地分成金额",
      prop: "fee_field",
      type: ["table"],
    },
    {
      label: "内容分成金额",
      prop: "fee_content",
      type: ["table"],
    },
    {
      label: "开始时间",
      prop: "created_at",
      type: ["table"],
    },
    {
      label: "完成时间",
      prop: "updated_at",
      type: ["table"],
    },
    {
      label: "订单状态",
      prop: "state",
      type: ["table"],
    },
    {
      label: "收款渠道",
      prop: "channel",
      type: ["table"],
    },
  ],
  api: Api.financial,
};
