<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps"/>
  </div>
</template>
<script>
import { Api } from "@/apis";
import { allProps } from "./config";
import  {orderStateList}  from "@/utils/index"
export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: function(){},
    };
  },
  data() {
    return {
      allProps,
      shopList:[],
      userInfo:{},
      orderStateList, 
      
    };
  },
  created() {
    this.userInfo=JSON.parse(localStorage.userInfo)
    this.getUserShopList()
  },
  methods: {
    changeApi(data,pageParams){
      let {id,name,token} = this.userInfo;
      return this.allProps.api.get+`?user_id=${id}&name=${name}&token=${token}&page=${pageParams.page}&pageSize=${pageParams.pageSize}&startAt=${data.startAt}&endAt=${data.endAt}&s_id=${data?.selectShop?.[1]}&m_id=${data?.selectShop?.[0]}`
      // 
    },
    getUserShopList() {
      let {id,name,token} = this.userInfo;
      this.$axios(
        Api.financial.userShopList+`?user_id=${id}&name=${name}&token=${token}`
      ).then((res) => {
        if (res.data.code == "1" && !res.data.data.length) {
          this.$message("该账号下暂无门店");
        } else if (res.code = "0" && res.data.data.length) {
          let list = [];
          res.data.data.forEach((i, index) => {
            list.push({ value: i.id, label: i.name, children: [] });
            i.devices.forEach((j) => {
              list[index].children.push({ value: j, label: "设备:" + j });
            });

          });
         allProps.items.forEach(i=>{
         if( i.prop==="selectShop")i.options=list
         })
        }
      });
    },
    dialogForm(title, row = {}) {
      allProps.dialog = {
        isShow: true,
        title,
      };
      allProps.data = { ...row };
    },
    changeTableList(data) {
      data.forEach((item) => {
        item.ename = item.name;
        item.s_id = item.shopid[0].s_id
        item.shopName = item.shopid[0].shopinfo[0].name
        item.gameName = item.datainfo.name;
        item.channel = "微信商户"
        this.orderStateList.forEach(v=>{
          if(item.state===v.id)item.state=v.label
        })
      });
      return data;
    },

  },
};
</script>
